import { Navigate } from "react-router-dom";
import { AuthError, checkPathIsValid, stripURLOrigin, useAuthentication } from "@cbe/auth";

export const AuthenticationWrapper = ({ children }: { children: JSX.Element }): JSX.Element => {
	const { isAuthenticated } = useAuthentication();

	if (!isAuthenticated) {
		const location = checkPathIsValid(
			stripURLOrigin(`${window.location.origin}/`, window.location.href)
		);

		return (
			<Navigate
				to={
					typeof location === "string"
						? `/?error=${AuthError.unauthenticated}&location=${encodeURIComponent(location)}`
						: `/?error=${AuthError.unauthenticated}`
				}
			/>
		);
	}

	return children;
};
