import loadable from "@loadable/component";
import { IRoute } from "app/routes";
import { loadableConfig } from "app/routes/loadableConfig";

import PaymentIcon from "@mui/icons-material/Payment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CallSplitIcon from "@mui/icons-material/CallSplit";

export const CBEPayRoutes: IRoute = {
	id: "CBEPay",
	guid: undefined,

	path: "/CBEPay",
	redirectTo: "/CBEPay/Accounts",

	icon: <PaymentIcon />,

	modules: [
		{
			id: "CBEPayAccounts",
			guid: undefined,

			path: "/CBEPay/Accounts",
			Component: loadable(() => import("./pages/Accounts"), loadableConfig),

			icon: <AccountBalanceWalletIcon />,
			sidebarPath: "/CBEPay/Accounts",

			children: [
				{
					index: true,
					Component: loadable(() => import("./pages/Accounts/pages/Overview"), loadableConfig),
				},
				{
					path: ":id",
					Component: loadable(() => import("./pages/Accounts/pages/Modify"), loadableConfig),
				},
			],
		},
		{
			id: "CBEPayMerchantConfig",
			guid: undefined,

			path: "/CBEPay/MerchantConfig",
			Component: loadable(() => import("./pages/MerchantConfig"), loadableConfig),

			icon: <BusinessCenterIcon />,
			sidebarPath: "/CBEPay/MerchantConfig",
		},
		{
			id: "CBEPaySplitConfig",
			guid: undefined,

			path: "/CBEPay/SplitConfig",
			Component: loadable(() => import("./pages/SplitConfig"), loadableConfig),

			icon: <CallSplitIcon />,
			sidebarPath: "/CBEPay/SplitConfig",
		},
	],
};
